<template>
   <div id="app">
      <router-view></router-view>
      <notifications />
   </div>
</template>

<script>
export default {
   name: 'App',
   beforeCreate() {
      if (localStorage.getItem('authorization')) {
         const authorization = JSON.parse(localStorage.getItem('authorization'));

         if (new Date(authorization.expires_at * 1000) < new Date()) {
            this.$store.commit('logout');
            return this.$router.push('/');
         }

         console.log(`Sessão expira em: ${new Date(authorization.expires_at * 1000).toLocaleString()}`);

    } else {
         if(this.$router.currentRoute.path != '/') {
            this.$router.push('/');
         }
      } 
   },
   
   created () {
      document.title = "Dashboard | Gestão Bibliotecas";
   },
}
</script>

<style>
</style>