<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Cadastrar Cidade" />

            <div class="row">
               <div class="col-md-6">
                  <div class="card bg-dark p-3">
                     <div class="row">
                        <div class="col-md-9 mb-3">
                           <label for="formControlName" class="form-label">Nome da Cidade</label>
                           <input v-model="city.name" type="text" class="form-control" id="formControlName" placeholder="Ex: São Paulo">
                        </div>
                        <div class="col-md-3 mb-3">
                           <label class="form-label">UF</label>
                           <select v-model="city.state_id" class="form-select" aria-label="Estado">
                              <option 
                                 v-for="uf in ufs" 
                                 :value="uf.id"
                                 :key="uf.id">
                                 {{ uf.name }}</option>
                           </select>
                        </div>
                     </div>
                     <div class="mb-2">
                        <button @click="create" type="button" class="btn btn-light  ">Cadastrar cidade</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue'
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp'

export default {
   name: "IndexPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },

   data() {
      return {
         city: {
            name: "",
            state_id: "26",
         },
         ufs: [],
      }
   },
   
   mounted() {
     this.ufs = ConstantsApp.Estados
   },

   methods: {
      async create() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.post('master/cities', this.city);
            this.$notify({title: response.data.message, type: 'success'});

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message, type: 'error'});
         }

         loader.hide();
      }
   },
}
</script>

<style lang="scss" scoped>
.page-content-wrapper {
   padding-top: 40px;
}
</style>