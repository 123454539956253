<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Logs de usuários" />
            
            <div class="row">
               <div class="col-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div v-if="logs.length > 0" class="table-responsive">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">#</th>
                                 <th>Biblioteca</th>
                                 <th>Usuário</th>
                                 <th>Ação</th>
                                 <th>Local</th>
                                 <th>Contexto</th>
                                 <th>Criação</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="log in logs" :key="log.id">
                                 <td>{{ log.id }}</td>
                                 <td>{{ log.library?.name ?? ' - ' }}</td>
                                 <td>{{ log.user?.name ?? ' - ' }}</td>
                                 <td>{{ actionRecupere(log.action) }}</td>
                                 <td>{{ modelRecupere(log.model) }}</td>
                                 <td style="max-width: 300px;">{{ contextFormat(log.context) }}</td>
                                 <td>
                                    {{ $dayjs(log.created_at).format("DD/MM/YYYY HH:mm:ss") }}
                                 </td>
                                 <td>
                                    <router-link :to="`/clients/${log.user?.id}/show`" v-if="log.user?.type == 'USER_LIBRARY'" class="text-white">
                                       <span class="material-icons-outlined">visibility</span>
                                    </router-link>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div v-else class="text-center pb-3">
                        Sem logs para o período
                     </div>
                  </div>   
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'

export default {
   name: "IndexPage",
   components: {
    PageTitleComponent,
    NavbarComponent,
},
   
   data() {
      return {
         logs: [],
      }
   },
   mounted() {
      this.findLogs();
   },

   methods: {
      async findLogs() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.get(`master/logs`);
            this.logs = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },

      contextFormat(item) {
         const data = JSON.parse(item);
         const values = Object.values(data);
         return values.join(' | ');
         
      },

      actionRecupere(action) {
         switch (action) {
            case 'create': return 'Criou';
            case 'update': return 'Atualizou';
            case 'delete': return 'Excluiu';
            case 'export': return 'Exportou';
            case 'view'  : return 'Acessou';
            default: return action;
         }
      },

      modelRecupere(model) {
         switch (model) {
            case 'Book': return 'Livro';
            case 'Loan': return 'Empréstimo';
            case 'Reader': return 'Leitor';
            case 'User': return 'Usuário';
            case 'Library': return 'Biblioteca';
            case 'LibraryToken': return 'Integração';
            default: return model;
         }
      },

   },
}
</script>
