<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Categorias Dewey" />
            
            <div class="row">
               <div class="col-md-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <form @submit.prevent="findCategories">
                        <div class="input-group mb-3">
                           <div class="me-3">
                              <input v-model="form.name" type="text" class="form-control" id="formControlName" placeholder="Nome">
                           </div>
                           <div class="me-3">
                              <input v-model="form.code" type="text" class="form-control" id="formControlCode" placeholder="Código">
                           </div>
                           <div class="me-3">
                              <select v-model="form.parent_id" class="form-select" aria-label="Estado">
                                 <option value="">Selecione</option>
                                 <option 
                                    v-for="parent in parentsCategories" 
                                    :value="parent.id"
                                    :key="parent.id">
                                    {{ parent.name }}
                                 </option>
                              </select>
                           </div>
                           <div>
                              <button type="submit" class="btn btn-light">Buscar</button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>

            <div class="row mt-5">
               <div class="col-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div v-if="deweyCategories.length > 0">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">Código</th>
                                 <th scope="col">Nome</th>
                                 <th>Nível</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="category in deweyCategories" :key="category.id">
                                 <td>{{ category.code }}</td>
                                 <td>{{ category.name }}</td>
                                 <td>
                                    <span v-if="category.level == 1" class="badge bg-primary px-3 rounded-4">Principal</span>
                                    <span v-else-if="category.level == 2" class="badge bg-secondary px-3 rounded-4">Secundária</span>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div v-else class="text-center pb-3">
                        Categoria não localizada
                     </div>
                  </div>   
               </div>
            </div>
         </div>
      </div>
      <ButtonAddComponent action="/categories/dewey/create" />
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp';
import ButtonAddComponent from '@/components/ButtonAddComponent.vue';

export default {
   name: "IndexPage",
   components: {
    PageTitleComponent,
    NavbarComponent,
    ButtonAddComponent
},
   
   data() {
      return {
         form: {
            name: "",
            code: "",
            parent_id: "",
         },
         parentsCategories: [],
         deweyCategories: [],
      }
   },

   mounted() {
      this.parentsCategories = ConstantsApp.Dewey;
      this.findCategories();
   },

   methods: {
      async findCategories() {
         let loader = this.$loading.show();
         this.deweyCategories = [];

         try {
            const response = await this.$axios.get(`master/dewey-categories?code=${this.form.code}&name=${this.form.name}&parent_id=${this.form.parent_id}`);
            this.deweyCategories = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      }
   },
}
</script>

<style lang="scss" scoped>
</style>