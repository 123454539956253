<template>
   <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="col-md-5 mx-auto">
         <div class="card bg-dark p-5">
            <h5 class="text-center fs-4 mb-4">Faça login</h5>

            <form @submit.prevent="login" method="post">
               <div class="mb-3">
                  <label for="inputEmail" class="form-label">E-mail</label>
                  <input v-model="form.email" type="text" class="form-control" id="inputEmail" placeholder="username@gmail.com">
               </div>

               <div class="mb-3">
                  <label for="inputPass" class="form-label">Senha</label>
                  <input v-model="form.password" type="password" class="form-control" id="inputPass" placeholder="">
               </div>

               <div class="mb-3">
                  <label class="form-label">Frase de segurança</label>
                  <input v-model="form.security_phrase" type="text" class="form-control" placeholder="Frase de segurança">
               </div>

               <div class="d-grid mt-2">
                  <button type="submit" class="btn btn-light">fazer login</button>
               </div>

            </form>

            <div v-if="message" class="mt-4 text-center text-danger">{{ message }}</div>
            
         </div>
      </div>
   </div>
</template>
<script>
export default {
   name: 'LoginPage',
   data() {
      return {
         message: '',
         form: {
            security_phrase: '',
            email: '',
            password: '',
         }
      }
   },
   methods: {
      async login() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.post('/master/auth/login', this.form);
            
            const data = {
               user: response.data.user,
               authorization: response.data.authorization,
            };

            this.$store.commit('login', data);

            window.localStorage.setItem('user', JSON.stringify(data.user));
            window.localStorage.setItem('authorization', JSON.stringify(data.authorization));
            
            window.location.href = '/home';
            
         } catch (error) {
            console.log(error);
            this.message = error.response.data.message;
         }

         loader.hide();
      }
   },
}
</script>