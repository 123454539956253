<template>
   <div class="d-flex">
      <div v-if="hideButtonBack"></div>
      <a v-else @click="$router.go(-1)" style="margin-right: 20px; color:#cccccc; cursor: pointer;">
         <span 
            class="material-icons-outlined" 
            style="margin-top: 18px; position: absolute; margin-left: -7px;">
            arrow_back</span>
      </a>
      <h4 class="page-title">{{ title }}</h4>
   </div>
</template>

<script>
export default {
   props: ['title', 'hideButtonBack']
}
</script>

<style lang="scss" scoped>
.page-title {
   position: relative;
   height: 60px;
   margin-bottom: 20px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   z-index: 10;
}
</style>