<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Enviar notificação por e-mail" />

            <div class="row">
               <div class="col-md-6 mb-5">
                  <div class="card bg-dark p-3">
                     <h5>Gestor</h5>
                     
                     <div class="mb-3">
                        <div>{{ client.name }}</div>
                     </div>

                     <div class="mb-3">
                        {{ (client.document != null) ? client.document : 'Sem documento' }}
                     </div>

                     <div class="mb-3">
                        E-mail: {{ client.email }}
                     </div>

                     <div class="mb-3">
                        Contato: {{ client.phone }}
                     </div>

                     <div class="mb-3">
                        Criado em {{ $dayjs(client.created_at).format('DD/MM/YYYY HH:MM') }}
                     </div>

                     <div class="mb-3">
                        <div>{{ client.library?.name }}</div>
                     </div>

                     <div class="mb-3">
                        Endereço: {{ `${client.library?.address} ${client.library?.address}` }}
                     </div>

                     <div class="mb-3">
                        {{ client.library?.city.name }} / {{ client.library?.state.name }}
                     </div>

                     <div class="mb-3">
                        <span class="me-2">Contato biblioteca: </span>
                        <span class="badge bg-light text-dark me-2">{{client.library?.public_phone ?? 'sem telefone' }}</span>
                        <span class="badge bg-light text-dark">{{client.library?.public_email ?? 'sem email'}}</span>
                     </div>

                  </div>
               </div>
               <div class="col-md-6 mb-5">
                  <div class="card bg-dark p-3">
                     <h5>Notificação</h5>
                     
                     <div class="mb-3">
                        <select v-model="form.type" class="form-select" aria-label="Default select example">
                           <option value="" selected>Selecione um assunto</option>
                           <option value="alert-account-delete">Alerta de exclusão programada</option>
                           <option value="books-limit-exceeded">Limite de livros próximo</option>
                        </select>
                     </div>

                     <div class="mb-3">
                        <button 
                           @click="sendNotification"
                           class="btn btn-sm btn-primary">Enviar notificação</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp';

export default {
   name: "NotificationCreatePage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },
   created() {
      this.findClient();
      this.ufs = ConstantsApp.Estados;
   },
   data() {
      return {
         client: {
            name: '',
            library: {
               state: {
                  name: '',
               },
               city: {
                  name: '',
               }
            },
            contracts: [],
         },
         ufs: [],
         form: {
            user_id: this.$route.params.id,
            type: '',
         }
      }
   },
   methods: {
      async findClient() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.get(`master/clients/${this.$route.params.id}`);
            this.client = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },
      async sendNotification() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.post(`master/notifications`, this.form);
            this.$notify({title: response.data.message, type: 'success'});

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message, type: 'error'});
         }

         loader.hide();
      }
   },
}
</script>