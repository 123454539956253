<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Faturas" />
            
            <div class="row">
               <div class="col-md-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div class="d-flex gap-3 justify-content-between mb-1">
                        <div class="w-100 mb-3">
                           <label>ID (Biblioteca ou Contrato)</label>
                           <input v-model="queryClient.name" type="text" class="form-control" id="formControlName" placeholder="Ex: 100">
                        </div>
                        <div class="w-100 mb-3">
                           <label>Filtro</label>
                           <select v-model="queryClient.param" class="form-select" aria-label="Parâmetro">
                              <option value="library_id">Biblioteca</option>
                              <option value="contract_id">Contrato</option>
                           </select>
                        </div>
                        <div class="w-100 mb-3">
                           <label>Mês Competência</label>
                           <select v-model="queryClient.month" class="form-select" aria-label="Período">
                              <option v-for="month in months" :key="month.d" :value="month.id">{{ month.name }}</option>
                           </select>
                        </div>
                        <div class="w-100 mb-3">
                           <label>Ano Competência</label>
                           <select v-model="queryClient.year" class="form-select" aria-label="Período">
                              <option v-for="i in years" :key="i" :value="i">{{ i }}</option>
                           </select>
                        </div>
                        <div class="w-100 mb-3">
                           <label>Status</label>
                           <select v-model="queryClient.status" class="form-select" aria-label="Status">
                              <option value="all">Todos</option>
                              <option value="1">Pendente</option>
                              <option value="2">Pago</option>
                              <option value="3">Cancelado</option>
                           </select>
                        </div>
                        <div class="w-100">
                           <div class="mt-4" style="padding-top:5px"></div>
                           <button 
                              @click="findContracts" 
                              type="button" 
                              class="btn btn-light w-100">
                              Buscar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row mt-5">
               <div class="col-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div v-if="invoices.length > 0" class="table-responsive">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">#</th>
                                 <th scope="col">Responsável</th>
                                 <th>Período</th>
                                 <th>Subtotal</th>
                                 <th>Total</th>
                                 <th class="text-center">Pagamento</th>
                                 <th>Vencimento</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="invoice in invoices" :key="invoice.id">
                                 <td>{{ invoice.id }}</td>
                                 <td>{{ invoice.library.company_name ?? invoice.library.name }}</td>
                                 <td>{{ invoice.month }} / {{ invoice.year }}</td>
                                 <td>R$ {{ (invoice.amount).toFixed(2) }}</td>
                                 <td>R$ {{ (invoice.total).toFixed(2) }}</td>
                                 <td class="text-center">
                                    <span v-if="invoice.status == 1" class="badge bg-warning">Aguardando</span>
                                    <span v-else-if="invoice.status == 2" class="badge bg-success">Pago</span>
                                    <span v-else-if="invoice.status == 3" class="badge bg-danger">Cancelado</span>
                                 </td>
                                 <td>{{ invoice.due_date ? $dayjs(invoice.due_date).format("DD/MM/YYYY") : 'Não informado' }}</td>
                                 <td>
                                    <router-link 
                                       :to="`/invoices/${invoice.id}/show`" 
                                       class="badge bg-dark me-3" 
                                       style="cursor: pointer;">
                                       Detalhes</router-link>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div v-else class="text-center pb-3">
                        Contratos não localizados
                     </div>
                  </div>   
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'

export default {
   name: "IndexPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },
   
   data() {
      return {
         queryClient: {
            name: "",
            param: "library_id",
            status: "all",
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
         },
         invoices: [],
         years: [ 2022, 2023, 2024, 2025 ],
         months: [
            { id: 1, name: "Janeiro" },
            { id: 2, name: "Fevereiro" },
            { id: 3, name: "Março" },
            { id: 4, name: "Abril" },
            { id: 5, name: "Maio" },
            { id: 6, name: "Junho" },
            { id: 7, name: "Julho" },
            { id: 8, name: "Agosto" },
            { id: 9, name: "Setembro" },
            { id: 10, name: "Outubro" },
            { id: 11, name: "Novembro" },
            { id: 12, name: "Dezembro" },
         ]
      }
   },
   mounted() {
      this.findContracts();
   },

   methods: {
      async findContracts() {
         let loader = this.$loading.show();
         this.invoices = [];

         try {
            const urlParams = new URLSearchParams();
            urlParams.set("param", this.queryClient.param);
            urlParams.set("name", this.queryClient.name);
            urlParams.set("status", this.queryClient.status);
            urlParams.set("month", this.queryClient.month);
            urlParams.set("year", this.queryClient.year);

            const response = await this.$axios.get(`master/invoices?${urlParams}`);
            this.invoices = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },
   },
}
</script>
