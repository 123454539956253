<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Novo cliente" />

            <div class="row">
               <div class="col-md-6 mx-auto">
                  <div class="card bg-dark rounded-4 p-5">
                     <h5 class="mb-4">Dados do Gestor</h5>
                     <div class="mb-3">
                        <label class="form-label">Nome</label>
                        <input v-model="form.name" type="text" class="form-control" placeholder="Ex: Ana Laura">
                     </div>

                     <div class="mb-3">
                        <label class="form-label">Email</label>
                        <input v-model="form.email" type="email" class="form-control" placeholder="Ex: exemplo@gmail.com">
                     </div>

                     <div class="mb-3">
                        <label class="form-label">Telefone</label>
                        <input v-model="form.phone" type="text" class="form-control" placeholder="Ex: (99) 99999-9999" autocomplete="off">
                     </div>

                     <div class="mb-3">
                        <label class="form-label">Senha</label>
                        <input v-model="form.password" autocomplete="off" type="password" class="form-control" placeholder="Senha">
                     </div>

                     <div class="mb-3">
                        <label class="form-label">Estado</label>
                        <select v-model="form.state_id" class="form-select" @change="findCities()">
                           <option v-for="uf in ufs" :key="uf.id" :value="uf.id">
                              {{ uf.name }}
                           </option>
                        </select>
                     </div>

                     <div class="mb-3">
                        <label class="form-label">Cidade</label>
                        <select v-model="form.city_id" class="form-select">
                           <option value="">Selecione</option>
                           <option v-for="city in cities" :key="city.id" :value="city.id">
                              {{ city.name }}
                           </option>
                        </select>
                     </div>

                     <div class="mt-2">
                        <button @click="create" type="button" class="btn btn-light px-3">Cadastrar</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp'

export default {
   name: "IndexPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },
   
   data() {
      return {
         form: {
            name: '',
            email: '',
            phone: '',
            plan_id: 5,
            password: '',
            state_id: 25,
            city_id: '',
         },
         ufs: [],   
         cities: [],
      }
   },

   mounted() {
     this.ufs = ConstantsApp.Estados;
     this.findCities();
   },

   methods: {
      async create() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.post(`master/clients`, this.form);
            this.$notify({title: response.data.message, type: 'success'});
            setTimeout(() => {
               this.$router.push(`/clients/${response.data.user.id}/show`);
            }, 2000);
            
         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message, type: 'error'});
         }

         loader.hide();
      },

      async findCities() {
         let loader = this.$loading.show();
         this.cities = [];

         try {
            const response = await this.$axios.get(`master/cities?state_id=${this.form.state_id}`);
            this.cities = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      }
   },
}
</script>
