<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Cadastrar Categoria Dewey" />

            <div class="row">
               <div class="col-md-6">
                  <div class="card bg-dark p-4">
                     <form @submit.prevent="create">
                        <div class="row">
                           <div class="col-md-9 mb-3">
                              <label for="formControlName" class="form-label">Nome</label>
                              <input v-model="form.name" type="text" class="form-control" id="formControlName" placeholder="Ex: Literatura portuguesa e espanhola">
                           </div>
                           <div class="col-md-9 mb-3">
                              <label for="formControlName" class="form-label">Código</label>
                              <input v-model="form.code" type="text" class="form-control" id="formControlName" placeholder="Ex: 860">
                           </div>
                        </div>
                        <div class="mb-2 mt-1">
                           <button type="submit" class="btn btn-light px-3">Cadastrar e continuar na página</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue'
import NavbarComponent from '@/components/NavbarComponent'

export default {
   name: "IndexPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },

   data() {
      return {
         form: {
            name: "",
            code: "",
         },
      }
   },
   
   methods: {
      async create() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.post('master/dewey-categories', this.form);
            this.$notify({title: response.data.message, type: 'success'});

            setTimeout(() => {
               this.$router.push(`/categories/dewey/create`);
            }, 1500);

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message, type: 'error'});
         }

         loader.hide();
      }
   },
}
</script>

<style lang="scss" scoped>
.page-content-wrapper {
   padding-top: 40px;
}
</style>