<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Clientes" />
            
            <div class="row">
               <div class="col-md-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div class="d-flex justify-content-between mb-4">
                        <div class="me-3 w-100">
                           <label>Encontrar</label>
                           <input v-model="queryClient.name" type="text" class="form-control" id="formControlName" placeholder="Digite um termo">
                        </div>
                        <div class="me-3 w-100">
                           <label>Filtro</label>
                           <select v-model="queryClient.param" class="form-select" aria-label="Filtro">
                              <option value="library_name">Nome</option>
                              <option value="company_name">Mantenedora</option>
                              <option value="document">Documento</option>
                           </select>
                        </div>
                        <div class="me-3 w-100">
                           <label>Tipo da conta</label>
                           <select v-model="queryClient.is_paying" class="form-select" aria-label="Parâmetro">
                              <option value="0">Teste</option>
                              <option value="1">Pagante</option>
                           </select>
                        </div>
                        <div class="me-3 w-100">
                           <label>Status</label>
                           <select v-model="queryClient.is_active" class="form-select" aria-label="Parâmetro">
                              <option value="1">Ativo</option>
                              <option value="0">Inativo</option>
                           </select>
                        </div>
                        <div class="me-3">
                           <div class="mt-4"></div>
                           <button 
                              @click="findClients"
                              type="button" 
                              class="btn btn-light px-4">
                              Buscar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row mt-5">
               <div class="col-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div v-if="libraries.length > 0" class="table-responsive">
                        <p class="py-2 text-start">Mostrando {{ libraries.length }} de {{ totalItems }} registros</p>
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">#</th>
                                 <th scope="col">Nome</th>
                                 <th>Mantenedora</th>
                                 <th>Documento</th>
                                 <th class="text-center">Tipo</th>
                                 <th class="text-center">Status</th>
                                 <th></th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="library in libraries" :key="library.id">
                                 <td>{{ library.id ?? '' }}</td>
                                 <td>{{ library.name }}</td>
                                 <td>{{ library.company_name }}</td>
                                 <td>{{ library.document }}</td>
                                 <td class="text-center">
                                    <span 
                                       v-if="library.is_paying"
                                       class="badge bg-primary">
                                       Pagante
                                    </span>
                                    <span v-else class="badge bg-secondary">
                                       Trial
                                    </span>
                                 </td>
                                 <td class="text-center">
                                    <span v-if="library.is_active" class="badge bg-success">Ativo</span>
                                    <span v-else class="badge bg-danger">Removido</span>
                                 </td>
                                 <td class="text-center">
                                    <button 
                                       class="btn btn-sm btn-outline-light" 
                                       data-bs-toggle="offcanvas" 
                                       data-bs-target="#offcanvasExample"
                                       @click="fetchInvoices(library.last_contract.id)">
                                       Faturas
                                    </button>
                                 </td>
                                 <td>
                                    <router-link 
                                       :to="`/clients/${library.id}/show`" 
                                       :class="`btn btn-sm btn-outline-light`">
                                       Detalhes</router-link>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div v-else class="text-center pb-3">
                        Sem contas criadas
                     </div>
                  </div>   
               </div>
            </div>
         </div>
      </div>
      <ButtonAddComponent action="/clients/create" />

      <!-- Offcanvas -->
      <div class="offcanvas bg-dark offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
         <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Faturas do cliente</h5>
            <button type="button" class="btn-close bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
         </div>
         <div class="offcanvas-body">
            <div v-for="invoice in invoices" :key="invoice.id" class="card text-white bg-secondary mb-3">
               <div class="p-3">
                  <div class="row">
                     <div class="col fw-semibold">
                        Fatura {{ invoice.month }} / {{ invoice.year }}
                     </div>
                     <div v-if="invoice.status == 1" class="col text-end text-warning fw-bold">
                        <span class="me-2">Pendente</span>
                        <i class="bi bi-circle-fill"></i>
                     </div>
                     <div v-else-if="invoice.status == 2" class="col text-end text-white fw-bold">
                        <div class="badge bg-success px-3 py-1">
                           <span class="me-2">Pago</span>
                           <i class="bi bi-circle-fill"></i>
                        </div>
                     </div>
                     <div v-else-if="invoice.status == 3" class="col text-end text-danger fw-bold">
                        <span class="me-2">Cancelado</span>
                        <i class="bi bi-circle-fill"></i>
                     </div>
                  </div>
                  <div>Valor: R$ {{ invoice.total.toFixed(2) }}</div>
                  <div v-if="invoice.due_date">
                     Pago em {{ $dayjs(invoice.due_date).format("DD/MM/YYYY") }}
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ButtonAddComponent from '@/components/ButtonAddComponent.vue';

export default {
   name: "IndexPage",
   components: {
    PageTitleComponent,
    NavbarComponent,
    ButtonAddComponent
},
   
   data() {
      return {
         queryClient: {
            name: "",
            param: "company_name",
            is_paying: '',
            is_active: 1,
         },
         libraries: [],
         totalItems: 0,
         totalPages: 0,
         currentPage: 1,
         invoices: []
      }
   },
   mounted() {
      this.findClients();
   },

   methods: {
      async findClients() {
         this.libraries = [];
         let loader = this.$loading.show();
         try {
            const urlParams = new URLSearchParams();
            urlParams.set("param", this.queryClient.param);
            urlParams.set("name", this.queryClient.name);
            urlParams.set("is_active", this.queryClient.is_active);
            urlParams.set("is_paying", this.queryClient.is_paying);

            const response = await this.$axios.get(`master/clients?${urlParams}`);
            this.libraries = response.data.data;
            this.totalPages = response.data.last_page;
            this.currentPage = response.data.current_page;
            this.totalItems = response.data.total;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },

      async fetchInvoices(contractId) {
         let loader = this.$loading.show();
         this.invoices = [];

         try {
            const urlParams = new URLSearchParams();
            urlParams.set("contract_id", contractId);
            const response = await this.$axios.get(`master/invoices?${urlParams}`);
            this.invoices = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      }
   },
}
</script>

<style lang="scss" scoped></style>