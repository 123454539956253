import { createStore } from 'vuex'

// populate with localStorage data
const localUser          = JSON.parse(localStorage.getItem('user'));
const localAuthorization = JSON.parse(localStorage.getItem('authorization'));

export default createStore({
  state: {
    userLogged: false,
    user: localUser,
    authorization: localAuthorization,
  },

  getters: {
    userToken (state) {
      return state.authorization.token;
    }
  },
  
  mutations: {
    login(state, data) {
      state.userLogged    = true;
      state.user          = data.user;
      state.authorization = data.authorization;
    },
    
    logout(state) {
      state.userLogged    = false;
      state.user          = null;
      state.authorization = null;
    },
  },

  actions: {
  },

  modules: {
  }
})