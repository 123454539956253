<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Editar biblioteca" />

            <form @submit.prevent="updateLibrary">
               <div class="row">
                  <div class="col-md-6">
                     
                     <div class="card bg-dark p-4 mb-4">
                        <h5 class="mb-3">Informações da biblioteca</h5>
                        <div class="mb-3">
                           <label for="formControlName" class="form-label">Nome</label>
                           <input v-model="library.name" 
                              class="form-control" 
                              id="formControlName" 
                              placeholder="Biblioteca Maria Santos">
                        </div>
                        <div class="mb-3">
                           <label for="formControlClassificationType" class="form-label">Tipo de Classificação</label>
                           <select v-model="library.classification_type" class="form-select" aria-label="Tipo de Classificação">
                              <option value="cdd">CDD</option>
                              <option value="cdu">CDU</option>
                              <option value="own">Classificação Própria</option>
                           </select>
                        </div>
                        <div class="mb-3">
                           <label for="formControlShelfMode" class="form-label">Tipo de Visualização no App</label>
                           <select v-model="library.shelf_mode" class="form-select" aria-label="Tipo de Visualização no App">
                              <option value="cdd">CDD</option>
                              <option value="cdu">CDU</option>
                              <option value="own">Classificação Própria</option>
                              <option value="collections">Coleções</option>
                           </select>
                        </div>
                        <div class="mb-3">
                           <label for="formControlPrimaryColor" class="form-label">Cor Principal</label>
                           <input v-model="library.primary_color" 
                              class="form-control"
                              id="formControlPrimaryColor"
                              type="color">
                        </div>
                        <div class="mb-3">
                           <label for="formControlTagPrintingLayout" class="form-label">Layout de Impressão das Etiquetas</label>
                           <select v-model="library.tag_printing_layout" class="form-select" aria-label="Layout de Impressão das Etiquetas">
                              <option value="1">Modelo 1</option>
                              <option value="2">Modelo 2</option>
                           </select>
                        </div>
                     </div>

                     <div class="card bg-dark p-4 mb-4">

                        <h5 class="mb-3">Empréstimos</h5>
                        <div class="mb-3">
                           <label for="formControlLoanDuration" class="form-label">Duração dos Empréstimos (dias)</label>
                           <input v-model="library.loan_duration" 
                              class="form-control" 
                              id="formControlLoanDuration" 
                              type="number" 
                              placeholder="Dias">
                        </div>
                        <div class="mb-3">
                           <label for="formControlLoanDuration" class="form-label">Limite de Empréstimos Simultâneos</label>
                           <input v-model="library.loans_same_time"
                              class="form-control"
                              type="number"
                              placeholder="Limite">
                        </div>
                        <div class="mb-3">
                           <label for="formControlLoanRenewable" class="form-label">Permitir Renovação Pelo Aplicativo</label>
                           <select v-model="library.loan_renewable" class="form-select" aria-label="Permitir Renovação">
                              <option value="1">Sim</option>
                              <option value="0">Não</option>
                           </select>
                        </div>
                        <div class="row">
                           <div class="col-md-6 mb-3">
                              <label for="formControlFineType" class="form-label">Tipo de Multa</label>
                              <select v-model="library.fine_type" class="form-select" aria-label="Tipo de Multa">
                                 <option value="1">Diário</option>
                                 <option value="2">Fixo</option>
                              </select>
                           </div>
                           <div class="col-md-6 mb-3">
                              <label for="formControlFineValue" class="form-label">Valor da Multa</label>
                              <input v-model="library.fine_value" 
                                 id="formControlFineValue" 
                                 type="number"
                                 class="form-select" 
                                 aria-label="Valor da Multa">
                           </div>
                        </div>
                        <div class="mb-3">
                           <label for="formControlLoansNeedAuthorizationCode" class="form-label">Exigir Código de Liberação</label>
                           <select v-model="library.loans_need_authorization_code" class="form-select" aria-label="Exigir Código de Liberação">
                              <option value="1">Sim</option>
                              <option value="0">Não</option>
                           </select>
                        </div>
                        <div class="mb-3">
                           <label for="formControlallowsLoansInTheApp" class="form-label">Permitir Empréstimos no Aplicativo</label>
                           <select v-model="library.allows_loans_in_the_app" class="form-select" aria-label="Permitir Empréstimos no Aplicativo">
                              <option value="1">Sim</option>
                              <option value="0">Não</option>
                           </select>
                        </div>
                        <div class="mb-3">
                           <label for="formControlLoanSelfReturn" class="form-label">Devolução Pelo Aplicativo</label>
                           <select v-model="library.loan_self_return" class="form-select" aria-label="Devolução Pelo Aplicativo">
                              <option value="1">Sim</option>
                              <option value="0">Não</option>
                           </select>
                        </div>

                     </div>
                     
                     <div class="card bg-dark p-4 mb-4">

                        <h5 class="mb-3">Características da biblioteca</h5>
                        <div class="mb-3">
                           <label for="formControlEmail" class="form-label">Tipo</label>
                           <select v-model="library.type" class="form-select" aria-label="Tipo da biblioteca">
                              <option value="academica">Acadêmica/Universitária</option>
                              <option value="pessoal">Acervo Pessoal</option>
                              <option value="comunidade">Comunidade</option>
                              <option value="empresa">Empresa</option>
                              <option value="escolar">Escola</option>
                              <option value="outro">Outro</option>
                           </select>
                        </div>
                        <div class="mb-3">
                           <label for="formControlPhone" class="form-label">Tamanho</label>
                           <select v-model="library.size" class="form-select" aria-label="Tamanho da biblioteca">
                              <option value="1000">Até mil livros</option>
                              <option value="4000">Até 4 mil livros</option>
                              <option value="8000">Até 8 mil livros</option>
                              <option value="8001">Mais de 8 mil livros</option>
                           </select>
                        </div>
                     </div>

                  </div>
                  <div class="col-md-6">
                     
                     <div class="card bg-dark p-4 mb-4">
                        <h5 class="mb-3">Logo da Biblioteca</h5>
                        <div v-if="logo.previewImageUrl || library.logo" class="mb-3 mx-auto">
                           <img :src="logo.previewImageUrl ?? library.logo" alt="" class="img-fluid logo-circle rounded-circle">
                        </div>
                        <div class="mb-3">
                           <input type="file"
                              @change="onFileChange"
                              class="form-control" 
                              id="formControlName" 
                              placeholder="Biblioteca Maria Santos">
                        </div>
                     </div>

                     <div class="card bg-dark p-4 mb-4">
                        <h5 class="mb-3">Link e Visibilidade</h5>
                        <div class="row">
                           <div class="col-md-6 mb-3">
                              <label for="formControlVisibleToAll" class="form-label">Link da biblioteca</label>
                              <div class="input-group">
                                 <input v-model="library.code"
                                    class="form-control" 
                                    id="formControlName" 
                                    aria-describedby="basic-addon3">
                                    <span id="basic-addon3" class="input-group-text px-4">libm.in</span>
                              </div>
                           </div>
                           <div class="col-md-6 mb-3">
                              <label for="formControlVisibleToAll" class="form-label">Quem pode acessar</label>
                              <select v-model="library.visible_to_all" class="form-select" aria-label="Visibilidade">
                                 <option value="1">Todos</option>
                                 <option value="0">Apenas usuários logados</option>
                              </select>
                           </div>
                        </div>
                     </div>


                     <div class="card bg-dark p-4 mb-4">

                        <h5 class="mb-3">Notificações e Alertas</h5>
                        <div class="mb-3">
                           <label for="formControlEmail" class="form-label">Email (opcional)</label>
                           <input v-model="library.email" 
                              class="form-control" 
                              id="formControlEmail"
                              placeholder="bibliotecamaria@gmail.com">
                        </div>
                        <div class="mb-3">
                           <label for="formControlPhone" class="form-label">Telefone (opcional)</label>
                           <input v-model="library.phone"
                              class="form-control" 
                              id="formControlPhone"
                              placeholder="(00) 00000-0000">
                        </div>
                        <div class="mb-3">
                           <label for="formControlSendWhatsapp" class="form-label">Enviar WhatsApp para leitores?</label>
                           <select v-model="library.send_whatsapp" class="form-select" aria-label="Enviar WhatsApp para leitores?">
                              <option value="1">Sim</option>
                              <option value="0">Não</option>
                           </select>
                        </div>

                     </div>

                     <div class="card bg-dark p-4 mb-4">
                        <h5 class="mb-3">Dados Fiscais</h5>
                        <div class="mb-3">
                           <label for="formControlCompanyName" class="form-label">Razão Social</label>
                           <input v-model="library.company_name" 
                              class="form-control" 
                              id="formControlCompanyName" 
                              placeholder="Escola Maria JA LTDA">
                        </div>
                        <div class="mb-3">
                           <label for="formControlName" class="form-label">Documento (CNPJ/CPF)</label>
                           <input v-model="library.document" 
                              class="form-control" 
                              id="formControlName" 
                              placeholder="00.000.000/0000-00">
                        </div>
                     </div>

                     <div class="card bg-dark p-4 mb-4">
                        <h5 class="mb-3">Endereço Fiscal</h5>
                        <div class="row">
                           <div class="col-md-9 mb-3">
                              <label for="formControlName" class="form-label">Logradouro</label>
                              <input v-model="library.address" 
                                 class="form-control" 
                                 id="formControlName" 
                                 placeholder="Rua ABC">
                           </div>
                           <div class="col-md-3 mb-3">
                              <label for="formControlName" class="form-label">Número</label>
                              <input v-model="library.address_number" 
                                 class="form-control" 
                                 id="formControlName" 
                                 placeholder="123">
                           </div>
                           <div class="col-md-6 mb-3">
                              <label for="formControlNeighborhood" class="form-label">Bairro</label>
                              <input v-model="library.neighborhood" 
                                 class="form-control" 
                                 id="formControlNeighborhood"
                                 placeholder="Centro">
                           </div>
                           <div class="col-md-6 mb-3">
                              <label for="formControlZipCode" class="form-label">CEP</label>
                              <input v-model="library.zip_code" 
                                 class="form-control" 
                                 id="formControlZipCode" 
                                 placeholder="49000-000">
                           </div>
                           <div class="col-md-4 mb-3">
                              <label for="formControlState" class="form-label">Estado</label>
                              <select v-model="library.state_id" @change="findCities(library.state_id)" class="form-select" aria-label="Estado">
                                 <option v-for="state in states" :value="state.id" :key="state.id">{{ state.name }}</option>
                              </select>
                           </div>
                           <div class="col-md-8 mb-3">
                              <label for="formControlCity" class="form-label">Cidade</label>
                              <select v-model="library.city_id" class="form-select" aria-label="Cidade">
                                 <option v-for="city in cities" :value="city.id" :key="city.id">{{ city.name }}</option>
                              </select>
                           </div>
                        </div>
                     </div>

                  </div>

                  <div class="col-md-12">
                     <div class="card bg-dark p-3">
                        <div class="gap-3 d-flex">
                           <router-link :to="`/libraries/${library.id}/show`" type="button" class="btn btn-outline-light px-4">Cancelar</router-link>
                           <button @click="updateLibrary" type="button" class="btn btn-light px-4">Atualizar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </form>

         </div>
      </div>

   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp'

export default {
   name: "EditPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },
   data() {
      return {
         library: {
            name: '',
            visible_to_all: '',
            company_name: '',
            document: '',
            address: '',
            address_number: '',
            neighborhood: '',
            zip_code: '',
            state_id: '',
            city_id: '',
         },
         states: [],
         cities: [],
         logo: {
            previewImageUrl: null,
            file: null,
         }
      }
   },
   mounted() {
      this.states = ConstantsApp.Estados,
      this.fetchLibrary()
   },
   methods: {
      async fetchLibrary() {
         const response = await this.$axios.get(`master/clients/${this.$route.params.id}`);
         this.library = response.data.library;
         this.findCities(this.library.state_id);
      },
      async findCities(stateId) {
         const response = await this.$axios.get(`master/cities?state_id=${stateId}`);
         this.cities = response.data;
      },
      async updateLibrary() {
         const loader = this.$loading.show();
         try {
            if (this.logo.file) {
               await this.updateLogo();
            }
            const response = await this.$axios.put(`master/libraries/${this.$route.params.id}`, this.library);
            loader.hide();
            this.$notify({title: response.data.message, type: 'success'});
         } catch (error) {
            console.log(error);
            loader.hide();
            this.$notify({title: error.response.data.message, type: 'error'});
         }
      },
      onFileChange(e) {
         const file = e.target.files[0];
         if (file.type !== 'image/png' && file.type !== 'image/jpeg' || file.size > 2000000) {
            this.$notify({title: 'Envie um arquivo PNG ou JPEG de até 2MB', type: 'error'});
            e.target.value = null;
            return;
         }
         this.logo.previewImageUrl = URL.createObjectURL(file);
         this.logo.file = file;
      },
      async updateLogo() {
         try {
            const formData = new FormData();
            formData.append('image', this.logo.file);
            formData.append('_method', 'PUT');
            await this.$axios.post('master/libraries/' + this.$route.params.id + '/upload', formData, {
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
            });
         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message, type: 'error'});
         }
      },
   },
}
</script>