<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Cidades" />
            
            <div class="row">
               <div class="col-md-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div class="input-group mb-3">
                        <div class="me-3">
                           <input v-model="city.name" type="text" class="form-control" id="formControlName" placeholder="Cidade">
                        </div>
                        <div class="me-3">
                           <select v-model="city.state_id" class="form-select" aria-label="Estado">
                              <option 
                                 v-for="uf in ufs" 
                                 :value="uf.id"
                                 :key="uf.id">
                                 {{ uf.name }}</option>
                           </select>
                        </div>
                        <div>
                           <button @click="findCities" type="button" class="btn btn-light">Buscar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row mt-5">
               <div class="col-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div v-if="cities.length > 0">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">Ordem</th>
                                 <th scope="col">Nome</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="city in cities" :key="city.id">
                                 <td>{{ city.id }}</td>
                                 <td>{{ city.name }}</td>
                                 <td>{{ city.uf }}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div v-else class="text-center pb-3">
                        Cidade não localizada
                     </div>
                  </div>   
               </div>
            </div>
         </div>
      </div>
      <ButtonAddComponent action="/cities/create" />
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp';
import ButtonAddComponent from '@/components/ButtonAddComponent.vue';

export default {
   name: "IndexPage",
   components: {
    PageTitleComponent,
    NavbarComponent,
    ButtonAddComponent
},
   
   data() {
      return {
         city: {
            state_id: 25,
            name: '',
         },
         ufs: [],
         cities: [],
      }
   },

   mounted() {
      this.ufs = ConstantsApp.Estados;
      this.findCities();
   },

   methods: {
      async findCities() {
         let loader = this.$loading.show();
         this.cities = [];

         try {
            const response = await this.$axios.get(`master/cities?state_id=${this.city.state_id}&name=${this.city.name}`);
            this.cities = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      }
   },
}
</script>

<style lang="scss" scoped>
</style>