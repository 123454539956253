<template>
   <nav class="navbar bg-dark">
      <div class="container">
         <router-link to="/home" class="navbar-brand">
            <img src="@/assets/logo.png" alt="Logo" height="24" class="d-inline-block align-text-top">
            <span class="text-white ms-2">Libmin Gestão</span>
         </router-link>
         <button @click="logout" class="btn btn-sm btn-link text-white" title="Sair do sistema">
            <span class="material-icons-outlined">logout</span>
         </button>
      </div>
   </nav>
</template>

<script>
export default {
   name: 'NavbarComponent',
   methods: {
      logout() {
         this.$store.commit('logout');
         localStorage.clear();
         
         this.$router.push('/');
      }
   }
}
</script>
<style lang="scss" scoped>
.bg-dark{
   background-color: var(--primary-color) !important;
}
</style>