<template>
   <router-link :to="action" id="fixedbutton">
      <span 
         class="material-icons-outlined" 
         style="font-size: 2.6rem; margin-top: 5px; margin-left: 4px; color: #fff;">
         add</span>      
   </router-link>
</template>

<script>
export default {
   props: ['action'],
}
</script>
<style lang="scss" scoped>
#fixedbutton {
   position: fixed;
   bottom: 35px;
   right: 35px; 

   cursor: pointer;
   background-color: var(--primary-color);
   border-radius: 50px;
   border: 1px solid var(--primary-color);
   width: 50px;
   height: 50px;
}
</style>