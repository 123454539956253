import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/scss/main.scss'
import 'bootstrap'
import VueTheMask from 'vue-the-mask'
import dayjs from 'dayjs'
import Notifications from '@kyvg/vue3-notification'

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

let authorizationToken = '';
if(localStorage.getItem('authorization')) {
  authorizationToken = JSON.parse(localStorage.getItem('authorization')).token;
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {'Authorization': `Bearer ${authorizationToken}`}
});

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueTheMask)
app.use(LoadingPlugin)
app.use(Notifications)
app.config.globalProperties.$axios = { ...axiosInstance }
app.config.globalProperties.$dayjs = dayjs
app.mount('#app')