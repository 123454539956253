<template>
   <div class="card" style="cursor: pointer;" @click="showIndex">
      <div class="row">
         <div class="col-md-3">
            <img :src="require('../assets/icons/'+icon)" alt="" class="ms-1" width="64">
         </div>
         <div class="col-md-9">
            <div class="title">{{ title }}</div>
            <div class="mt-1">
               {{ totalItems }}
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: ['icon', 'title', 'totalItems', 'actionIndex', 'actionCreate'],
   methods: {
      showIndex() {
         this.$router.push(this.actionIndex);
      },
   }
}
</script>

<style lang="scss" scoped>
.card {
   background-color: var(--primary-color);
   padding: 10px;

   .title {
      font-size: 1.1rem;
      font-weight: 600;
   }
}
</style>